<template>
  <el-dialog
    class="el-dialog-order"
     :width="isMobileScreen ? '90%' : '80%'"
    :title="isUnlink ? 'Unlink Order' : 'Link Order'"
    :visible="dialogLinkOrder"
    @close="handleLinkOrderDialogClose"
    @open="handleLinkOrderDialogOpen"
  >
    <div :class="isUnlink ? 'div-unlink-order' : 'div-link-order'" v-loading="isOrderTableLoading">
      <table-order v-if="!isOrderTableLoading" :hasPagination="!isUnlink" :orderPagination="orderPagination" :hasRadio="!isUnlink"
        :isOrderTableLoading="isOrderTableLoading" orderType="DELIVERED_AND_BARGING_NOMINATION" :orderTableData="linkOrderTableData"
        @handleOrderPageChange="handleOrderPageChange" @handleOrderPageSizeChange="handleOrderPageSizeChange" @handleOrderRadioSelectChange="handleOrderRadioSelectChange"
      />
    </div>
    <div class="el-dialog__footer">
      <el-button outline round  @click="handleLinkOrderDialogClose">
        Cancel
      </el-button>
      <el-button round  type="primary" @click="handleConfirmLinkOrderClick" :disabled="!linkedOrderSelected">
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import TableOrder from './TableOrder'
import { getOrderById, getOrders } from '@/services/modules/order.js'
export default {
  name: 'DialogLinkOrder',
  components: { TableOrder },
  props: {
    dialogLinkOrder: Boolean,
    orderType: String,
    order: Object,
    isUnlink: Boolean
  },
  data () {
    const orderPagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      linkOrderTableData: [],
      isOrderTableLoading: true,
      linkedOrderSelected: null,
      tradeType: null,
      orderPagination,
      orderRadioData: null
    }
  },
  methods: {
    handleOrderPageChange (pageNumber) {
      this.getLinkOrders(this.orderPagination.pageSize, pageNumber - 1)
    },
    handleOrderPageSizeChange (pageSize) {
      this.getLinkOrders(pageSize, 0)
    },
    handleLinkOrderDialogOpen () {
      this.isOrderTableLoading = true
      this.linkOrderTableData = []
      this.order.sellerSysOrganizationId === this.myCompanyId ? this.tradeType = 'SALES' : this.tradeType = 'PURCHASE'
      if (!this.isUnlink) {
        this.getLinkOrders(50, 0)
      } else {
        getOrderById(this.order.sellerSysOrganizationId === this.myCompanyId ? this.order.upstreamOrderId : this.order.downstreamOrderId).then(res => {
          if (res?.code === 1000) {
            this.linkedOrderSelected = res.data
            this.linkOrderTableData = [res.data]
          }
          this.isOrderTableLoading = false
        })
      }
    },
    handleLinkOrderDialogClose () {
      this.$emit('handleLinkOrderDialogClose')
    },
    handleConfirmLinkOrderClick () {
      this.$emit('handleConfirmLinkOrderClick', this.linkedOrderSelected, this.tradeType === 'SALES', !this.isUnlink)
    },
    handleOrderRadioSelectChange (row) {
      this.linkedOrderSelected = row
    },
    getLinkOrders (pageSize, pageNumber) {
      const query = `${this.tradeType === 'PURCHASE'
        ? `sellerSysOrganizationId:'${this.myCompanyId}' and upstreamOrder.id is null`
          : `buyerSysOrganizationId:'${this.myCompanyId}' and downstreamOrder is null`}
            and orderType in ('DELIVERED', 'BARGING_NOMINATION') and id ! '${this.order.id}'&sort=updatedAt desc`
      getOrders(pageSize, pageNumber, query).then(res => {
        if (res?.code === 1000) {
          this.linkOrderTableData = res.data.content
          this.orderPagination.total = res.data.totalElements
          this.orderPagination.pageNumber = res.data.pageable.pageNumber + 1
          this.isOrderTableLoading = false
        }
      })
    }
  },
  computed: {
    ...mapState([
      'isMobileScreen',
    ]),
    myCompanyId () {
      return this.$store.state.currentCompany.id
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
  /deep/.el-dialog__body{
    padding-top: 0;
  }
</style>
